<template>
  <section v-if="isIndexPage">
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <!-- end appbar nav icon -->
      <v-toolbar-title class="headline font-weight-semibold"
        >Settings</v-toolbar-title
      >
      <!-- end toolbar title -->
      <v-spacer />
      <!-- end -->
    </v-app-bar>
    <!-- end app bar -->

    <v-row>
      <v-col cols="4" v-for="(page, index) in pages" :key="index">
        <v-card>
          <v-card-title>
            <span class="title font-weight-light" v-text="page.title" />
          </v-card-title>
          <!-- end title -->
          <v-card-subtitle v-text="page.subtitle" />
          <!-- end subtitle -->
          <v-card-actions>
            <v-btn color="secondary" :to="page.to" text>
              {{ page.actionText }}
            </v-btn>
            <!-- end button -->
          </v-card-actions>
          <!-- end actions -->
        </v-card>
        <!-- end card -->
      </v-col>
      <!-- end column -->
    </v-row>
    <!-- end row -->
  </section>
  <!-- end section -->
  <router-view v-else />
  <!-- end router view -->
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

export default {
  name: 'SettingsPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon
  },

  data: () => ({
    pages: [
      {
        title: 'App Version Control',
        subtitle: 'Manage android and iOS version',
        actionText: 'Manage',
        to: { name: 'settings.appVersions' }
      },
      {
        title: 'Privacy Policy',
        subtitle: 'Update the Privacy Policy.',
        actionText: 'Modify',
        to: { name: 'settings.privacyPolicy' }
      },
      {
        title: 'Terms and Condition',
        subtitle: 'Update the Terms and Condition.',
        actionText: 'Modify',
        to: { name: 'settings.termsAndCondition' }
      }
    ]
  }),

  computed: {
    isIndexPage() {
      return this.$route.name === 'settings'
    }
  }
}
</script>
