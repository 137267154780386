<template>
  <v-expansion-panel ref="panel">
    <v-expansion-panel-header>
      <div class="py-1">
        <span class="font-weight-semibold">Version:</span> {{ app.version }}
      </div>
      <!-- end div -->
    </v-expansion-panel-header>
    <!-- end header -->
    <v-expansion-panel-content class="py-2">
      <AppVersionControlEditForm
        :app="app"
        :is-active="isActive"
        class="pt-5"
        @init-update="$emit('updated', $event)"
        @init-delete="$emit('deleted', app)"
      />
      <!-- end app version control edit form -->
    </v-expansion-panel-content>
    <!-- end title -->
  </v-expansion-panel>
  <!-- end app version control item -->
</template>

<script>
import AppVersionControlEditForm from './AppVersionControlEditForm'

export default {
  name: 'AppVersionControlItem',

  components: {
    AppVersionControlEditForm
  },

  props: {
    app: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isActive: false
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$watch(
        () => {
          return this.$refs.panel.isActive
        },
        isActive => (this.isActive = isActive)
      )
    })
  }
}
</script>
