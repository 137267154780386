<template>
  <v-form @submit.prevent="createOrUpdateTermsAndCondition">
    <ContentEditor
      v-model="form.content"
      :extensions="tiptapExtensions"
      placeholder="Type in Terms and Condition"
      :error-message="errorMessage"
      @clear-error="form.content = null"
    />
    <!-- end content editor -->
    <div class="text-right mt-4">
      <v-btn
        v-if="hasId"
        @click="confirmDeletion = !confirmDeletion"
        type="button"
        large
        color="red"
        :disabled="confirmDeletion"
        class="mr-2"
      >
        <span class="font-weight-semibold white--text"
          >Delete Terms and Condition</span
        >
      </v-btn>
      <v-btn type="submit" large color="primary" :loading="isLoading">
        <span class="font-weight-semibold">
          {{ hasId ? 'Update' : 'Create' }} Terms and Condition
        </span>
      </v-btn>
    </div>
    <!-- end wrapper -->
    <ConfirmModal
      v-if="hasId"
      v-model="confirmDeletion"
      title="Delete Terms and Condition"
      message="The current content will no longer be available in this terms and condition."
    >
      <v-btn
        @click="confirmDeletion = !confirmDeletion"
        type="button"
        text
        class="font-weight-semibold"
        >Close</v-btn
      >
      <v-btn
        @click="deleteTermsAndCondition"
        type="button"
        text
        color="red"
        class="font-weight-semibold"
        :disabled="isDeleting"
        :loading="isDeleting"
        >Delete</v-btn
      >
    </ConfirmModal>
    <!-- end confirm modal -->
  </v-form>
  <!-- end form -->
</template>

<script>
import {
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
import ContentEditor from '@/components/ContentEditor'
import ConfirmModal from '@/components/modals/ConfirmModal'
import Form from '@/utils/form'
import { mapMutations } from 'vuex'

const tiptapExtensions = [
  History,
  Blockquote,
  Link,
  Underline,
  Strike,
  Italic,
  ListItem,
  BulletList,
  OrderedList,
  [
    Heading,
    {
      options: {
        levels: [1, 2, 3]
      }
    }
  ],
  Bold,
  Code,
  HorizontalRule,
  Paragraph,
  HardBreak
]

export default {
  name: 'TermsAndConditionForm',

  components: {
    ContentEditor,
    ConfirmModal
  },

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      // tiptap extensions
      tiptapExtensions,
      isLoading: false,
      isDeleting: false,
      confirmDeletion: false,
      errors: undefined,
      form: new Form({
        _resource_: this.value._resource_,
        id: this.value.id,
        content: this.value.content,
        created_at: this.value.created_at,
        updated_at: this.value.updated_at
      })
    }
  },

  computed: {
    hasId() {
      return !!this.value.id
    },
    errorMessage() {
      return this.errors?.content[0]
    }
  },

  watch: {
    value(value) {
      this.form = new Form({
        _resource_: value._resource_,
        id: value.id,
        content: value.content,
        created_at: value.created_at,
        updated_at: value.updated_at
      })
    },
    'form.content'(value) {
      if (value !== null && !!this.errorMessage) {
        this.errors = undefined
      }
    }
  },

  methods: {
    ...mapMutations({
      callSnackbar: 'callSnackbar'
    }),
    createOrUpdateTermsAndCondition() {
      this.isLoading = true

      const form = { content: this.form.content }

      this.$api
        .post('/terms-and-condition', form)
        .then(({ data }) => {
          const response = data.data
          this.$nextTick(() => {
            this.$emit('append-form-data', response)
            this.callSnackbar({
              state: true,
              text: `Terms and Condition has been ${
                this.hasId ? 'updated' : 'created!'
              }`,
              color: this.hasId ? 'info' : 'success'
            })
          })
        })
        .catch(error => (this.errors = error?.response?.data?.errors))
        .finally(() => (this.isLoading = false))
    },
    deleteTermsAndCondition() {
      this.isDeleting = true

      this.$api
        .delete(`/terms-and-condition/${this.form.id}`)
        .then(() => {
          this.$nextTick(() => {
            this.$emit('reset-form')
            this.callSnackbar({
              state: true,
              text: 'Terms and Condition has been deleted!',
              color: 'red'
            })
          })
        })
        .catch(error => (this.errors = error?.response?.data?.errors))
        .finally(() => {
          this.isDeleting = false
          this.confirmDeletion = false
        })
    }
  }
}
</script>
