<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title v-if="form.id">Edit App Version </v-toolbar-title>
      <v-toolbar-title v-else>App Version </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-6">
      <v-form @submit.prevent="save">
        <label class="text-field-label">App ID</label>
        <v-text-field
          flat
          solo
          class="mt-2"
          v-model="form.app_id"
          :error-messages="form.$getError('app_id')"
        />

        <label class="text-field-label">Platform</label>
        <v-select
          flat
          solo
          class="mt-2"
          v-model="form.platform"
          :items="['android', 'ios']"
          :error-messages="form.$getError('platform')"
        />

        <label class="text-field-label">Minimum Version Code</label>
        <v-text-field
          auto-grow
          flat
          solo
          class="mt-2"
          v-model="form.min_version_code"
          :error-messages="form.$getError('min_version_code')"
        />
        <v-row>
          <v-col class="py-0">
            <v-switch
              color="blue"
              v-model="form.enable"
              inset
              label="Enabled"
            ></v-switch>
          </v-col>
          <v-col class="py-0">
            <v-switch
              color="blue"
              v-model="form.force"
              inset
              label="Force"
            ></v-switch>
          </v-col>
        </v-row>
        <div class="d-flex justify-space-between mt-8">
          <v-btn
            depressed
            type="button"
            color="grey lighten-3"
            @click="$emit('close')"
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-btn depressed type="submit" color="primary" :loading="loading"
            >Submit</v-btn
          >
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Form from '@/utils/form'
import AppVersion from '@/models/AppVersion'
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      form: new Form({})
    }
  },
  mounted() {
    this.initializeForm()
  },

  methods: {
    async save() {
      this.loading = true
      const res = await new AppVersion(this.form.$data()).save()
      this.$emit('saved', new AppVersion(res.data))
      this.loading = false
      this.initializeForm()
    },
    initializeForm() {
      if (this.value) {
        this.form = new Form(this.value)
      } else {
        this.resetForm()
      }
    },
    resetForm() {
      this.form = new Form({
        app_id: '',
        min_version_code: '',
        platform: 'android',
        enable: true,
        force: true
      })
    }
  },

  watch: {
    value(val) {
      this.initializeForm()
    }
  }
}
</script>

<style></style>
