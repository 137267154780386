<template>
  <div class="confirm-modal">
    <v-btn
      @click="isActive = !isActive"
      large
      color="red"
      outlined
      class="font-weight-semibold"
      :disabled="isActive"
      >Delete</v-btn
    >
    <!-- end trigger -->
    <ConfirmModal
      v-model="isActive"
      title="Delete App Version Control"
      message="The version will no longer be available in this app version control."
    >
      <v-btn
        @click="isActive = !isActive"
        type="button"
        text
        class="font-weight-semibold"
        >Close</v-btn
      >
      <v-btn
        @click="onSubmit"
        type="button"
        text
        color="red"
        class="font-weight-semibold"
        :disabled="isLoading"
        :loading="isLoading"
        >Delete</v-btn
      >
    </ConfirmModal>
    <!-- end confirm modal -->
  </div>
  <!-- end confirm modal -->
</template>

<script>
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mapMutations } from 'vuex'

export default {
  name: 'AppVersionControlDeleteModal',

  components: {
    ConfirmModal
  },

  props: {
    app: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    isActive: false,
    isLoading: false,
    errors: {}
  }),

  methods: {
    ...mapMutations({
      callSnackbar: 'callSnackbar'
    }),
    onSubmit() {
      this.errors = {}
      this.isLoading = true

      this.$api
        .delete(`/app-versions/${this.app.id}`)
        .then(() => {
          this.isActive = false
          this.$nextTick(() => {
            this.$emit('init-parent-delete')
            this.callSnackbar({
              state: true,
              text: `${this.app.version} has been deleted!`,
              color: 'red'
            })
          })
        })
        .catch(error => (this.errors = error?.response?.data?.errors))
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
