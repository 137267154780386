<template>
  <section>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <!-- end appbar nav icon -->
      <v-toolbar-title class="headline font-weight-semibold"
        >App Version Control</v-toolbar-title
      >
      <!-- end toolbar title -->
      <v-spacer />
      <!-- end -->
    </v-app-bar>
    <!-- end app bar -->

    <v-row align="center">
      <v-col cols="12" sm="12" md="3">
        <v-tabs v-model="tabs.current" align-with-title>
          <v-tabs-slider color="secondary" />
          <!-- end slider color -->
          <v-tab v-for="(tab, index) in tabs.list" :key="index">
            {{ tab }}
          </v-tab>
          <!-- end tab -->
        </v-tabs>
        <!-- end tabs -->
      </v-col>
      <!-- end col -->
      <v-col cols="12" sm="12" md="4">
        <AppVersionControlAddModal
          :platform="tabs.list[tabs.current]"
          @added="onAddApp"
        />
        <!-- end app version control add modal -->
      </v-col>
    </v-row>

    <v-row class="px-4">
      <v-col cols="12" sm="12" md="6">
        <v-tabs-items v-model="tabs.current">
          <v-tab-item v-for="(tab, index) in tabs.list" :key="index">
            <v-sheet v-if="isLoading">
              <v-skeleton-loader
                v-for="loader in 6"
                :key="loader"
                type="list-item-three-line"
              />
              <!-- end skeleton loader -->
            </v-sheet>
            <!-- end loading template -->
            <NotFound v-else-if="list.length === 0">
              <div class="text-center">
                <h1 class="py-4">No {{ title }} App Version Found.</h1>
                <p class="body-1">
                  Click ‘Create {{ title }} Version’ button to add new
                  {{ title }} App Version
                </p>
              </div>
              <!-- end text center -->
            </NotFound>
            <!-- end not found -->
            <v-expansion-panels v-else class="app-version" popout focusable>
              <AppVersionControlItem
                v-for="app in list"
                :key="app.id"
                :app="app"
                @updated="onUpdateApp"
                @deleted="onDeleteApp"
              />
              <!-- end app version control item -->
            </v-expansion-panels>
            <!-- end expansion panels -->
          </v-tab-item>
          <!-- end tab item -->
        </v-tabs-items>
        <!-- end tabs items -->
      </v-col>
      <!-- end column -->
    </v-row>
    <!-- end row -->
  </section>
  <!-- end section -->
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import AppVersionControlItem from './AppVersionControlItem'
import AppVersionControlAddModal from './AppVersionControlAddModal'
import NotFound from '@/components/NotFound'

export default {
  name: 'AppVersionControlPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    AppVersionControlItem,
    AppVersionControlAddModal,
    NotFound
  },

  data() {
    return {
      tabs: {
        current: 0,
        list: ['android', 'ios']
      },
      isLoading: false,
      errors: {},
      list: [],
      meta: {}
    }
  },

  computed: {
    title() {
      return this.tabs.current === 0 ? 'Android' : 'iOS'
    }
  },

  watch: {
    'tabs.current': {
      immediate: true,
      handler(key) {
        this.fetchData(this.tabs.list[key])
      }
    }
  },

  methods: {
    fetchData(platform) {
      this.isLoading = true

      this.$api
        .get(`/app-versions?platform=${platform}&sort_by_created_at=desc`)
        .then(({ data }) => {
          this.list = data.data
          this.meta = data.meta
        })
        .catch(error => (this.errors = error?.response?.data?.errors))
        .finally(() => (this.isLoading = false))
    },
    onUpdateApp(event) {
      const list = [...this.list]
      const newList = list.map(app => {
        if (event.id === app.id) {
          return event
        }
        return app
      })
      this.list = newList
    },
    onDeleteApp(event) {
      const list = [...this.list]
      const newList = list.filter(app => event.id !== app.id)
      this.list = newList
    },
    onAddApp(event) {
      const list = [...this.list]
      const newList = [event, ...list]
      this.list = newList
    }
  }
}
</script>

<style scoped>
.app-version {
  padding: 1.5rem 0;
}
.app-version .v-expansion-panel::before {
  box-shadow: none;
}
.app-version .v-expansion-panel--active::before {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
