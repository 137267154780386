<template>
  <div>
    <tiptap-vuetify
      @input="onInput"
      :value="value"
      :extensions="extensions"
      :placeholder="placeholder"
      :style="getStyle"
      :class="`${errorMessage ? 'has-error mb-3' : ''}`"
    />
    <!-- end titptap vuetify -->
    <div v-if="errorMessage" class="v-text-field__details">
      <div class="v-messages theme--light error--text">
        <div class="v-messages__wrapper">
          <div class="v-messages__message" v-text="errorMessage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import the component and the necessary extensions
import { TiptapVuetify } from 'tiptap-vuetify'

export default {
  name: 'ContentEditor',

  components: { TiptapVuetify },

  props: {
    extensions: {
      type: Array,
      required: true,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Type in content'
    },
    value: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: '600px'
    },
    boxShadow: {
      type: String,
      default:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
    },
    errorMessage: {
      type: [String, undefined],
      default: undefined
    }
  },

  computed: {
    getStyle() {
      return {
        '--editor-height': this.height,
        '--editor-box-shadow': this.boxShadow
      }
    }
  },

  watch: {
    value(value) {
      if (value !== null && value === '<p></p>') {
        this.$emit('clear-error')
      }
    }
  },

  methods: {
    onInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style lang="scss">
.tiptap-vuetify-editor .v-card .tiptap-vuetify-editor__content {
  background-color: white;
  border-radius: 3px;
  border-color: transparent;
}
.tiptap-vuetify-editor.has-error .v-card .tiptap-vuetify-editor__content {
  border: 1px solid var(--v-error-base) !important;
}
.tiptap-vuetify-editor .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: var(--editor-box-shadow);
}
.tiptap-vuetify-editor .ProseMirror {
  min-height: 100%;
  height: var(--editor-height);
}
</style>
