<template>
  <v-row justify="center">
    <v-dialog v-model="isActive" max-width="600px">
      <template #activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          large
          color="primary"
          class="font-weight-semibold"
        >
          Create {{ title }} Version
        </v-btn>
        <!-- end button -->
      </template>
      <!-- end template -->

      <v-form ref="form" @submit.prevent="onSubmit">
        <v-card>
          <v-card-title class="headline"
            >Create {{ title }} Version</v-card-title
          >
          <!-- end title -->
          <v-card-subtitle>
            Note: Make sure that the new version has been released.
          </v-card-subtitle>
          <!-- end subtitle -->
          <v-card-text>
            <v-container class="pa-0">
              <v-row>
                <v-col cols="12" sm="12" md="6" class="py-md-0">
                  <v-text-field
                    v-model="form.version"
                    label="Version"
                    :rules="[rulesMixin.isRequired]"
                    :error-messages="errors.version"
                    outlined
                    required
                  />
                  <!-- end text-field -->
                </v-col>
                <!-- end column -->
                <v-col cols="12" sm="12" md="6" class="py-md-0">
                  <v-select
                    v-model="form.upgrade_guide"
                    :items="upgradeGuideList"
                    label="Upgrade Guide"
                    :rules="[rulesMixin.isRequired]"
                    :error-messages="errors.upgrade_guide"
                    outlined
                    required
                  />
                  <!-- end select -->
                </v-col>
                <!-- end column -->
                <v-col cols="12" sm="12" class="py-md-0">
                  <v-text-field
                    v-model="form.title"
                    label="Title"
                    :error-messages="errors.title"
                    outlined
                  />
                  <!-- end text-field -->
                </v-col>
                <!-- end column -->
                <v-col cols="12" sm="12" class="py-md-0">
                  <v-text-field
                    v-model="form.message"
                    label="Message"
                    :error-messages="errors.message"
                    outlined
                  />
                  <!-- end text-field -->
                </v-col>
                <!-- end column -->
                <v-col cols="12" sm="12" class="py-md-0">
                  <v-text-field
                    v-model="form.store_link"
                    label="Store Link"
                    :rules="[rulesMixin.validUrl]"
                    :error-messages="errors.store_link"
                    outlined
                  />
                  <!-- end text-field -->
                </v-col>
                <!-- end column -->
              </v-row>
              <!-- end row -->
            </v-container>
            <!-- end container -->
          </v-card-text>
          <!-- end text -->
          <v-card-actions>
            <v-spacer />
            <!-- end spacer -->
            <v-btn
              @click="isActive = false"
              type="button"
              class="font-weight-semibold"
              text
            >
              Close
            </v-btn>
            <!-- end button -->
            <v-btn
              type="submit"
              class="font-weight-semibold"
              text
              color="secondary"
              :loading="isLoading"
              :disabled="isLoading || !isReady"
            >
              Create
            </v-btn>
            <!-- end button -->
          </v-card-actions>
          <!-- end actions -->
        </v-card>
        <!-- end card -->
      </v-form>
    </v-dialog>
    <!-- end dialog -->
  </v-row>
  <!-- end app version control add modal -->
</template>

<script>
import Form from '@/utils/form'
import FormCommonRulesMixin from '@/utils/mixins/form-common-rules'
import { mapMutations } from 'vuex'

export default {
  name: 'AppVersionControlAddModal',

  mixins: [FormCommonRulesMixin],

  props: {
    platform: {
      type: String,
      default: 'android'
    }
  },

  data() {
    return {
      isActive: false,
      isLoading: false,
      errors: {},
      form: new Form({
        version: '',
        upgrade_guide: '',
        title: '',
        message: '',
        store_link: ''
      }),
      upgradeGuideList: ['recommended', 'required']
    }
  },

  computed: {
    title() {
      return this.platform === 'android' ? 'Android' : 'iOS'
    },
    isReady() {
      const getRequired =
        this.form.version !== '' && this.form.upgrade_guide !== ''
      if (this.form.store_link) {
        return this.hasStoreLink && getRequired
      } else {
        return getRequired
      }
    },
    hasStoreLink() {
      const storeLink = this.form.store_link
      if (storeLink !== null && storeLink.length > 0) {
        return typeof this.rulesMixin.validUrl(storeLink) !== 'string'
      }
      return false
    }
  },

  watch: {
    isActive() {
      if (this.form.$isDirty()) this.form.$reset()
    }
  },

  methods: {
    ...mapMutations({
      callSnackbar: 'callSnackbar'
    }),
    onSubmit() {
      this.errors = {}
      this.isLoading = true

      const form = { ...this.form, platform: this.platform }

      this.$api
        .post(`/app-versions`, form)
        .then(({ data }) => {
          this.isActive = false
          this.$nextTick(() => {
            this.$emit('added', data.data)
            this.callSnackbar({
              state: true,
              text: `${data.data.version} has been added!`,
              color: 'success'
            })
            this.form.$reset()
            this.$refs.form.resetValidation()
          })
        })
        .catch(error => (this.errors = error?.response?.data?.errors))
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
