<template>
  <div class="no-data">
    <v-img
      lazy-src="@/assets/no-data.svg"
      src="@/assets/no-data.svg"
      aspect-ratio="16/9"
      :max-width="maxWidth"
      :max-height="maxHeight"
      alt="No data"
      title="No data"
    />
    <!-- end image -->
    <slot />
    <!-- end slot -->
  </div>
  <!-- end no data -->
</template>

<script>
export default {
  name: 'NotFound',

  props: {
    maxWidth: {
      type: String,
      default: '100%'
    },
    maxHeight: {
      type: String,
      default: '100%'
    }
  }
}
</script>

<style scoped>
.no-data {
  padding: 3rem;
  position: relative;
}
</style>
