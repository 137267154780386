<template>
  <section>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <!-- end appbar nav icon -->
      <v-toolbar-title class="headline font-weight-semibold">
        App Version Control
      </v-toolbar-title>
      <!-- end toolbar title -->
      <v-spacer />
      <!-- end -->
    </v-app-bar>
    <!-- end app bar -->

    <v-row align="center">
      <v-col lg="12" md="12" sm="12">
        <v-simple-table>
          <thead>
            <tr>
              <th>App ID</th>
              <th>Minimum Version Code</th>
              <th>Enable</th>
              <th>Force</th>
              <th>Date Updated</th>
              <th width="1%"></th>
            </tr>
          </thead>
          <tbody>
            <app-version-item
              v-for="app in appVersions"
              :key="app.id"
              :appVersion="app"
              @updated="appVersionUpdated"
              @deleted="appVersionDeleted"
            />
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-dialog v-model="addDialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          fixed
          bottom
          right
          large
          rounded
          color="primary"
          class="mb-10 mr-10"
          elevation="16"
        >
          <v-icon left>
            {{ icons.plus }}
          </v-icon>
          Add New App
        </v-btn>
      </template>
      <app-version-form @close="addDialog = false" @saved="appVersionAdded" />
    </v-dialog>
  </section>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import AppVersionItem from './AppVersionItem.vue'
import { findIndex } from 'lodash'
import { mdiPlusCircleOutline } from '@mdi/js'
import AppVersionForm from './AppVersionForm'

export default {
  name: 'AppVersionControlPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    AppVersionItem,
    AppVersionForm
  },

  data() {
    return {
      addDialog: false,
      appVersions: [],
      loading: false
    }
  },

  computed: {
    icons() {
      return {
        plus: mdiPlusCircleOutline
      }
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.loading = true

      this.$api
        .get(`/app-versions`)
        .then(({ data }) => {
          this.appVersions = data.data
        })
        .finally(() => (this.loading = false))
    },

    appVersionUpdated(appVersion) {
      const index = findIndex(this.appVersions, { id: appVersion.id })
      if (index > -1) {
        this.appVersions.splice(index, 1, appVersion)
      } else {
        this.appVersions.push(appVersion)
      }
    },

    appVersionDeleted(appVersion) {
      const index = findIndex(this.appVersions, { id: appVersion.id })
      if (index > -1) {
        this.appVersions.splice(index, 1)
      }
    },

    appVersionAdded(appVersion) {
      this.addDialog = false
      this.appVersions.push(appVersion)
    }
  }
}
</script>

<style scoped>
.app-version {
  padding: 1.5rem 0;
}
.app-version .v-expansion-panel::before {
  box-shadow: none;
}
.app-version .v-expansion-panel--active::before {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
</style>
