<template>
  <tr>
    <td>{{ appVersion.app_id }}</td>
    <td>{{ appVersion.min_version_code }}</td>
    <td>{{ appVersion.enable }}</td>
    <td>{{ appVersion.force }}</td>
    <td>{{ dateUpdated }}</td>
    <td>
      <div class="d-flex">
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="grey lighten-1"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.pencil }}</v-icon>
            </v-btn>
          </template>
          <app-version-form
            v-model="appVersion"
            @close="dialog = false"
            @saved="updated"
          />
        </v-dialog>
        <v-dialog v-model="deleteDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="grey lighten-1" v-bind="attrs" v-on="on">
              <v-icon>{{ icons.trash }}</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title> Confirm Delete </v-card-title>
            <v-card-text class="text-body-1">
              Are you sure you want to delete this item ?
            </v-card-text>
            <v-card-actions class="justify-end pa-4">
              <v-btn text @click="deleteDialog = false"> Cancel </v-btn>
              <v-btn depressed color="error" @click="deleteVersion">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </td>
  </tr>
</template>

<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Form from '@/utils/form'
import { mdiTrashCan, mdiPencil } from '@mdi/js'
import AppVersionForm from './AppVersionForm'
import AppVersion from '@/models/AppVersion'

dayjs.extend(utc)

export default {
  props: {
    appVersion: {
      type: Object,
      required: true
    }
  },
  components: {
    AppVersionForm
  },

  data() {
    return {
      dialog: false,
      deleteDialog: false,
      form: new Form({
        app_id: '',
        platform: 'android'
      })
    }
  },

  computed: {
    icons() {
      return {
        trash: mdiTrashCan,
        pencil: mdiPencil
      }
    },
    dateUpdated() {
      return dayjs
        .utc(this.appVersion.updated_at)
        .local()
        .format('MMM DD, YYYY hh:mm A')
    }
  },

  methods: {
    updated(appVersion) {
      this.dialog = false
      this.$emit('updated', appVersion)
    },
    async deleteVersion() {
      await new AppVersion(this.appVersion).delete()
      this.deleteDialog = false
      this.$emit('deleted', this.appVersion)
    }
  }
}
</script>

<style></style>
