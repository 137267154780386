import { isRequired, validUrl } from '../rules'

export default {
  data: () => ({
    rulesMixin: {
      isRequired,
      validUrl
    }
  })
}
