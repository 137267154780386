const patternUrl = new RegExp(
  '^https?:\\/\\/' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
  'i'
) // fragment locator

// value is required
export const isRequired = value => !!value || 'This field is required.'

// value should be correct URL
export const validUrl = value => {
  const hasValue = value && value.length > 0

  if (hasValue) {
    return (
      patternUrl.test(value) ||
      'This field should start with http:// or https://'
    )
  }

  return hasValue || true
}
