<template>
  <v-row>
    <v-col cols="12" class="py-md-0">
      <v-select
        ref="upgradeGuide"
        v-model="form.upgrade_guide"
        :items="upgradeGuideList"
        label="Upgrade Guide"
        :rules="[rulesMixin.isRequired]"
        :error-messages="errors.upgrade_guide"
        outlined
        required
      />
      <!-- end select -->
    </v-col>
    <!-- end column -->
    <v-col cols="12" sm="12" class="py-md-0">
      <v-text-field
        v-model="form.title"
        label="Title"
        :error-messages="errors.title"
        outlined
      />
      <!-- end text-field -->
    </v-col>
    <!-- end column -->
    <v-col cols="12" sm="12" class="py-md-0">
      <v-text-field
        v-model="form.message"
        label="Message"
        :error-messages="errors.message"
        outlined
      />
      <!-- end text-field -->
    </v-col>
    <!-- end column -->
    <v-col cols="12" sm="12" class="py-md-0">
      <v-text-field
        v-model="form.store_link"
        label="Store Link"
        :error-messages="errors.store_link"
        outlined
      />
      <!-- end text-field -->
    </v-col>
    <!-- end column -->
    <v-col cols="12" class="px-4 d-flex justify-space-between">
      <v-btn
        @click="onSubmit"
        large
        color="secondary"
        class="font-weight-semibold"
        :loading="isLoading"
        :disabled="isLoading"
        >Update</v-btn
      >
      <!-- end update -->
      <AppVersionControlDeleteModal
        :app="app"
        @init-parent-delete="$emit('init-delete')"
      />
      <!-- end delete -->
    </v-col>
    <!-- end column -->
  </v-row>
  <!-- end row -->
</template>

<script>
import Form from '@/utils/form'
import FormCommonRulesMixin from '@/utils/mixins/form-common-rules'
import AppVersionControlDeleteModal from './AppVersionControlDeleteModal'
import { mapMutations } from 'vuex'

export default {
  name: 'AppVersionControlEditForm',

  components: {
    AppVersionControlDeleteModal
  },

  mixins: [FormCommonRulesMixin],

  props: {
    app: {
      type: Object,
      default: () => ({})
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isLoading: false,
      errors: {},
      form: new Form({
        upgrade_guide: this.app.upgrade_guide,
        title: this.app.title,
        message: this.app.message,
        store_link: this.app.store_link
      }),
      upgradeGuideList: ['recommended', 'required']
    }
  },

  watch: {
    app(value) {
      this.form = new Form({
        upgrade_guide: value.upgrade_guide,
        title: value.title,
        message: value.message,
        store_link: value.store_link
      })
    },
    isActive() {
      if (this.form.$isDirty()) this.form.$reset()
    }
  },

  methods: {
    ...mapMutations({
      callSnackbar: 'callSnackbar'
    }),
    onSubmit() {
      this.errors = {}
      this.isLoading = true

      this.$api
        .put(`/app-versions/${this.app.id}`, this.form)
        .then(({ data }) => {
          this.$nextTick(() => {
            this.$emit('init-update', data.data)
            this.callSnackbar({
              state: true,
              text: `${this.app.version} has been updated!`,
              color: 'success'
            })
          })
        })
        .catch(error => (this.errors = error?.response?.data?.errors))
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>

<style>
.v-menu {
  z-index: 8;
}
</style>
