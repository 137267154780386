<template>
  <section>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <!-- end appbar nav icon -->
      <v-toolbar-title class="headline font-weight-semibold"
        >Privacy Policy</v-toolbar-title
      >
      <!-- end toolbar title -->
      <v-spacer />
      <!-- end -->
    </v-app-bar>
    <!-- end app bar -->

    <v-row class="px-4">
      <v-col cols="12">
        <v-skeleton-loader
          v-if="isLoading"
          v-bind="attrs"
          type="image, actions"
        />
        <!-- end loader -->
        <PrivacyPolicyForm
          v-else
          v-model="form"
          @append-form-data="onAppendFormData"
          @reset-form="onResetForm"
        />
        <!-- end privacy policy form -->
      </v-col>
      <!-- end column -->
    </v-row>
    <!-- end row -->
  </section>
  <!-- end section -->
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import PrivacyPolicyForm from './PrivacyPolicyForm'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'PrivacyPolicyPage',

  mixins: [ControlsMixin],

  components: {
    AppBarNavIcon,
    PrivacyPolicyForm
  },

  data: () => ({
    form: {},
    errors: {},
    isLoading: false,
    attrs: {
      class: 'mt-6',
      boilerplate: true
    }
  }),

  created() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.isLoading = true

      this.$api
        .get('/privacy-policy')
        .then(({ data }) => {
          let response = {}

          if (!isEmpty(data?.data)) {
            response = data.data
          }
          this.form = response
        })
        .catch(error => {
          this.errors = error?.response?.data?.message
        })
        .finally(() => (this.isLoading = false))
    },
    onAppendFormData(event) {
      this.form = event
    },
    onResetForm() {
      this.form = {
        _resource_: null,
        id: null,
        content: null,
        created_at: null,
        updated_at: null
      }
    }
  }
}
</script>
